@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');



:root {
    --mantine-color-scheme: 'light' !important;
    --main-color: #0053ED;
    --base-gray-land: #151515d2;
    --off-gray: #e9e9e9;
    --item-gray: #9D9D9D;
    --text-gray: #5f6368;
    --trans: #ffffff00;
    --px11: 0.6875rem;
    --px12: 0.75rem;
    --px13: 0.813rem;
    --px14: 0.875rem;
    --px15: 0.9375rem;
    --px16: 1rem;
    --px17: 1.0625rem;
    --px18: 1.125rem;
    --px19: 1.1875rem;
    --px20: 1.25rem;
    --titleFont: 2.2rem;
    --hoverBorder: rgb(231, 231, 231);
    --hoverBorder: rgba(231, 231, 231, 0.068);
    --frostedBorder: solid 1.5px rgba(255, 255, 255, 0.3);
    --heroFont: 3.6rem;
    --frostedBackground: rgba(255, 255, 255, 0.071);
    --grayBackground: #f1f3f5;
    --gradientedFrostedBorder: solid 1.5px;
    --frostedText: rgba(255, 255, 255, 0.7);
    --hoverBorder: rgb(231, 231, 231);

}


* {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif !important;
    color: var(--main-text);
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}

button {
    cursor: pointer;
}

a {
    text-decoration: none;
}



.material-icons-outlined {
    font-family: 'Material Icons Outlined' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

* {
    accent-color: black;
    /* 
    color: #002467 */
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overscroll-behavior-y: none;
}

.navbar {
    width: 100%;
    display: flex;
    align-items: center;

    z-index: 9999;
    padding: 15px 90px;

    transition: 0.2s ease;
    display: flex;
    justify-content: space-between;

}

.nav-region {
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.nav-spacer {
    width: 100%;
    height: 120px;
}

.navbar.scrolled {
    background-color: var(--main-color)
}

.navbar.scrolled>.nav-left>ul>li>a {}

.navbar.scrolled>.nav-left>ul>li>div>a {}

.nav-left {
    display: flex;
    gap: 30px;
    ;
}

.nav-left>a {
    display: flex;
    align-items: center;
}

.nav-left>a>img {

    height: 24px;


}

.navigation-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.nav-items {
    display: flex;
    list-style-type: none;
    align-items: center;
    gap: 30px;
}

.nav-items>li>a {
    text-decoration: none;
    color: white;

    font-size: var(--px15);
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 5px;
}

.nav-items>li>a>i {
    font-size: 19px;
    color: white;
}

.nav-items>li>a:hover {
    opacity: 0.7;
}














.dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.126);
    z-index: 1;
    width: fit-content;
    padding: 20px 25px;
    border-radius: 10px;
    gap: 35px;

    opacity: 0;
    transition: 0.6s ease;
    width: fit-content;
    margin: auto;
    ;


}

.grouper-divider {
    width: 100%;
    height: 1px;
    background-color: #eaeaea;
    margin: 7px 0px
}

.dropdown-content>a:last-of-type {
    color: var(--main-color);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px rgb(0 0 0 / 5%) !important;
    padding-top: 9px;
    margin-top: -6px;
}



.dropdown-content>a:last-of-type>i {
    font-size: 30px;
    color: var(--main-color);
}


/* Show dropdown when hovering over hover-dropdown */
.hover-dropdown:hover .dropdown-content {
    display: flex;

    opacity: 1;
    /* Display dropdown on hover */
}

/* Optional: Add a little visual cue that these have dropdowns */
.hover-dropdown>a:after {

    font-size: 0.8em;
}

.hover-dropdown>a:hover {
    opacity: 0.7;
}

.dropdown-grouper {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.dropdown-grouper>h4 {
    font-size: var(--px14);
    font-weight: 700;

    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 5px;

}

.dropdown-grouper>a:first-of-type {
    padding-top: 0px;
}

.dropdown-grouper>h4>i {
    font-size: 20px;
    color: var(--main-color);
}

.dropdown-grouper>a {
    color: black;
    text-decoration: none;
    font-size: var(--px15);
    font-weight: 600;

    padding-top: 15px;
    opacity: 0.8;
}

.hover-dropdown>a {
    text-decoration: none;
    color: white;
    font-size: var(--px15);
    font-weight: 700;
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    margin-top: 25px;
    text-transform: uppercase;


}

.dropdown-grouper>a:hover {
    opacity: 1;
}

.dropdown-content>a:last-of-type {
    border: none;
}

.hover-dropdown>a>i {
    font-size: 30px;
    margin-right: -5px;
    color: white;
}


.dropdown-content>a {
    color: black;
    text-decoration: none;
    font-size: var(--px16);
    font-weight: 600;

    padding-bottom: 10px;

}

.dropdown-icon {
    display: flex;

}

.dropdown-icon>i {
    font-size: 30px;
    margin-left: 50px;
    margin-top: -28px;
    color: white;
    margin-bottom: -10px;
}

.dropdown-content>a:hover {
    color: var(--main-color);
}

.mobile-btn {
    display: none;
}

.support-nav {
    display: flex;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    height: 60px;
    padding: 0px 90px;

}

.support-nav-right {
    font-weight: 500;
    display: flex;
    gap: 20px;
    align-items: center;
}

.support-nav-left {
    display: flex;
    gap: 10px;
}


.support-nav-right>a {
    font-size: 14px;
    font-weight: 600;
}

.support-nav-right>a:hover {
    text-decoration: underline;
}

.support-nav-right>a:last-of-type {
    background-color: var(--main-color);
    padding: 9px 22px;
    border-radius: 35px;
    color: white;
    font-weight: 600;
    font-size: 15px;
}

.support-nav-left>p {
    color: black;
}

.hero-section>video {
    width: 100%;
    height: 100vh;
    position: absolute;
    object-fit: cover;
    filter: brightness(0.5);
}

.hero-section-content {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1;

    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 1;
    padding-top: 75px;

}

.halfed-hero {
    height: 80vh;
    padding-top: 80px;

}

.hero-section-spacer {
    height: 100vh
}

.hero-no-container-spacer {
    height: 170px
}

.contact-page {
    display: flex;
    flex-direction: column;


}

.contact-page>.contact-title {
    height: 37vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

}

.contact-page>div>.bigTitle-pages {
    font-size: 50px;
}

.contact-page>.cardRowPages {
    margin-top: 0px;
}

.contact-title>h4 {
    margin: 0px;
}

.contact-title>h2 {
    margin: 0px;
}

.hero-section-content>h1 {
    font-size: var(--heroFont);
    font-family: 'Josefin Sans', sans-serif !important;
    color: white;
    font-weight: 700;
    line-height: 70px;
    max-width: 800px;
}

.hero-section-content>span {
    font-size: var(--px14);
    color: white;
    font-weight: 700;
    padding: 7px 13px;
    background-color: rgba(255, 255, 255, 0.121);
    border-radius: 10px;
    margin-bottom: 15px;
    text-transform: uppercase;
    backdrop-filter: blur(15px);
}

.hero-section-buttons>a {
    padding: 15px 25px;
    background-color: var(--main-color);
    color: white;
    font-weight: 700;
    font-size: var(--px16);
    border-radius: 50px;

    text-decoration: none;
    text-transform: uppercase;
}

.hero-section-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.hero-section-buttons>a:last-of-type {
    background-color: rgba(255, 255, 255, 0.121);
    color: white;
    backdrop-filter: blur(15px);
}

.hero-section-content>p {
    font-size: var(--px16);
    color: white;
    font-weight: 500;
    margin-top: -5px;

}

/* accordion video section */
.accordionSection {

    background-color: rgb(0 0 0 / 0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 80px 0px;
}

.accordion-video>video {
    object-fit: contain;
    height: 600px;
    width: 600px;
}





.textColumn {
    display: flex;
    flex-direction: column;
    width: 555px;

}

.campaignTitle {
    color: #002467;
    line-height: 46px;
    font-size: 39px;
    font-family: 'Josefin Sans', sans-serif !important;
    font-weight: 700;
    margin: 0 16px;

}

.boxOpen {
    display: flex;
    flex-direction: row;
    box-shadow: 0px 4px 144px 0px rgba(0, 0, 0, 0.09);
    margin: 15px 0px;
    border-radius: 20px;
    padding: 18px 20px;
    gap: 13px;
}

.boxClosed>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;
    width: 100%;
}

.boxOpen>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;
    width: 100%;
}


.boxOpen>div>.frontIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: inherit;
    object-fit: contain;
    color: #0053ED;
    border-radius: 50px;
}

.boxClosed {

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    gap: 13px;

}

.boxClosed>div>.frontIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    object-fit: contain;
    color: #0053ED;
    border-radius: 50px;
    font-size: 30px;
}

#specialImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 50px;

}

#specialIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    object-fit: contain;
    border-radius: 50px;
    color: white;
    background-color: #0053ED;
}

.boxText {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}



.customImg {
    width: 24px;
}

.boxText>h3 {
    color: #002467;
    line-height: 25px;
    font-size: 19px;
    font-weight: 700;

}

.boxP {
    color: rgb(0, 0, 0, .8);
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
}

.arrow {

    border: none;
    background-color: inherit;
    margin-left: auto;
    font-size: 30px;
    opacity: 75%;

}


/* tab section */
.tabsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 60px 0;
    background-color: rgb(0 0 0 / 0%);
    max-width: 1200px;

}

.tabsTitle {
    display: flex;
    flex-direction: row;

}

.darkText {
    white-space: pre;
    color: #002467;
    font-weight: 700;
    font-size: 39px;
    font-family: 'Josefin Sans', sans-serif !important;

}

.blueText {
    font-family: 'Josefin Sans', sans-serif !important;
    color: #0053ED;
    font-weight: 700;
    font-size: 39px;

}

.tabButtons {
    display: flex;
    flex-direction: row;
    cursor: pointer;

}

.tabButton>h4 {
    color: #002467;
    font-weight: 700;
    font-size: 20px;
}

.tabButton>h4:hover {
    color: #0053ED;
}

.tabButton {
    padding: 13px;
    border: none;
    border-bottom: solid 4px transparent;
    border-radius: 0;
    background-color: inherit;


}

.tabButtonActive {
    border-color: var(--main-color)
}

.tabButtonActive>h4 {
    color: var(--main-color) !important;
}

.line {
    height: 1px;
    border-width: 0px;
    width: 60%;
    color: rgb(0, 0, 0, .13);
    background-color: rgb(0, 0, 0, .13);
}

.cardRow {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 60%;
    width: 100%
}

.card {
    background-color: rgb(#f9f9f9);
    margin: 20px;
    border-radius: 29px;
    box-sizing: border-box;
    padding: 35px;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    height: 300px;
    box-shadow: 0px 4px 144px 1px rgba(0, 0, 0, 0.06);
}

.card>i {

    object-fit: contain;
    box-sizing: content-box;
    padding: 14px;
    color: white;
    background-color: #0053ED;
    border-radius: 50px;


}

#icon2 {
    object-fit: contain;
    box-sizing: content-box;
    padding: 14px;
    color: white;
    background-color: #0053ED;
    border-radius: 50px;
    width: 25px;
    height: 25px;
}

.cardTitle {
    color: #002467;
    font-size: 22px;
    font-weight: 700;
    margin-top: 15px;
}

.cardText {
    color: #002467;
    line-height: 27px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 5px;

}

.cardText>p>a {
    font-weight: 600;
}


/* story section */

.storySection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 550px;
    justify-items: center;
    align-items: center;
    margin-top: 60px;
}

.textLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 550px;
    gap: 15px;
    padding-left: 70px;
    padding-bottom: 40px;
}

.textLeft>h2 {
    line-height: 46px;
    font-size: 39px;
    font-weight: 700;
    color: #002467;
    font-family: 'Josefin Sans', sans-serif !important;

}

.textLeft>p {
    font-size: 17px;
    line-height: 31px;
    font-weight: 400;
    color: black;
}

.dlj {
    font-size: 17px;
    line-height: 31px;
    font-weight: 600;
    color: black;
}

.secondP {
    padding-top: 10px;
}

.rightPics {
    display: grid;

    grid-template-columns: 286px 248px;
    grid-template-rows: 315px 204px;
    grid-template-areas:
        "leftpics rightpic"
        "leftpics rightpic"
    ;
    gap: 31px 30px;
    margin-right: auto;
    padding-left: 35px;
}


.rightPics>img {
    object-fit: contain;

}

.leftpics {
    grid-area: leftpics;
    display: grid;
    grid-template-rows: 315px 204px;
    gap: 31px 0px;

}

.topleft {
    width: 286px;
    height: 315px;
}

.bottomleft {
    width: 286px;
    height: 204px;
}

.rightpic {
    grid-area: rightpic;
    width: 248px;
    height: 550px;


}

.box-video {
    display: none;
}



.footer {
    margin: auto;
    width: 90%;
    margin-top: 170px;
    max-width: 1200px;

}

.footer-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px
}

.footer-links {
    display: flex;
    gap: 50px;
}

.footer-link-group>ul>li>a {
    text-decoration: none;
    color: var(--base-gray-land);
    font-size: var(--px15);
    font-weight: 500;
    line-height: 22px;
    margin-top: 2px;
}

.footer-logo>img {
    width: 200px;
    margin-bottom: 20px;

}

.footer-logo {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.footer-logo>a {
    text-decoration: none;
    color: black;
    ;
    font-weight: 600;
    font-size: var(--px14);
}

.footer-logo>p {
    font-size: var(--px14);
    font-weight: 400;
    line-height: 22px;
    color: var(--base-gray-land);
}



.footer-link-group>ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
}

.footer-bottom>ul {
    display: flex;
    list-style-type: none;
    gap: 20px;
}

.footer-bottom>ul>li>a {
    text-decoration: none;
    color: var(--base-gray-land);
    font-size: var(--px14);
    font-weight: 400;
    line-height: 22px;
    margin-top: 2px;
}

.footer-link-group>ul>li>a:hover {
    opacity: 0.7;
    text-decoration: underline;
}

.footer-bottom {
    height: 65px;
    display: flex;
    align-items: center;
}

.footer-bottom>p {
    font-size: var(--px14);
    font-weight: 400;
    line-height: 22px;
    color: var(--base-gray-land);
}

.footer-link-group>h4 {
    font-size: var(--px15);
    font-weight: 700;
    color: var(--base-gray-land);
    margin-bottom: 5px;
}


.footer-link-group>ul>li>span {
    font-size: var(--px15);
    font-weight: 400;
    line-height: 22px;
    color: var(--base-gray-land);
    margin-top: 2px;
}


@media screen and (max-width: 1130px) {

    .hero-no-container-spacer {
        display: none;
    }

    .contact-title>h2 {
        text-align: center;
    }

    .contact-title>h4 {
        margin: 0px;
    }

    .contact-page>.contact-title {
        height: 33vh;
    }

    .footer-logo>img {
        display: none;
    }

    .footer-links {
        flex-direction: column;
        margin-top: 35px;

    }

    .footer-content {
        flex-direction: column;
    }

    .footer-logo {
        width: 100%;
        align-items: center;
    }

    .footer-content {
        margin-bottom: 40px;
    }

    .footer-bottom {
        flex-direction: column;
        height: auto;
        margin-bottom: 40px;
    }

    footer {
        margin-top: 50px !important;
    }

    .storySection {
        flex-direction: column;
        display: flex;
        height: auto;
        max-width: 520px;
        gap: 30px;
        margin: auto;
        margin-top: 80px;
    }

    .textLeft>h2 {
        color: #002467;
        font-size: 22px;
        font-weight: 700;

    }

    .textLeft {
        padding: 0px 20px;
        width: 100%;
        gap: 4px;
    }

    .rightPics>img {
        display: none;
    }

    .rightPics {
        display: flex;
        margin-right: 0px;
        padding-left: 0px;
        width: 100%;
        padding: 0px 20px;
        ;
    }

    .topleft {
        width: 100%;
    }

    .bottomleft {
        width: 100%;
    }

    .leftpics {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .leftpics>img {
        object-fit: cover;
        border-radius: 20px;
    }

    .nav-region {
        position: sticky;
        width: 100%;
        z-index: 9999;
        top: -60px;
    }


    .ham-btn>i {
        color: var(--main-color);
        font-size: 27px;
    }

    .mobile-menu {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 99999;

        background-color: var(--main-color);
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    .nav-items {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
        margin-top: 70px;

    }

    .sec-banner>img {
        max-height: 300px;
    }

    .nav-items>li>a {
        font-size: var(--px18);
        font-weight: 600;
        color: white;

        width: 100%;
        display: block;
        padding-left: 20px;
    }

    .nav-items>li {
        width: 100%;
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);
        padding-bottom: 15px;
    }

    .hover-dropdown>a {
        margin-top: 0px;
        padding-bottom: 0px;
        font-size: var(--px18);
        font-weight: 600;
        padding-left: 20px;
    }

    .ham-btn {
        background-color: white;
        border: none;
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.3);
        padding: 25px;


    }

    .floating-btn {
        display: block;
        position: fixed;
        right: 25px;
        top: 25px;
        padding: 20px;
        z-index: 99999
    }


    .floating-btn {
        position: fixed;
        top: 0px;
        right: 0px;
        z-index: 9999;
    }

    .hover-dropdown>a {
        padding-bottom: 0px;
        margin-top: 0px;
    }

    .hero-section>video {
        top: 0px;
    }

    .navbar {
        padding: 20px 40px;
    }

    .mobile-menu {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 99999;

        background-color: var(--main-color);
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        display: flex;
    }

    .nav-items {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
    }

    .mobile-btn {
        display: block;


    }

    .support-nav {
        padding: 0px 40px;
    }

    .ham-btn {
        background-color: white;
        border: none;
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.3);
        padding: 25px;


    }

    .ham-btn>i {
        color: var(--main-color);
    }

    .desktop-menu {
        display: none !important;
    }


    .accordionSection {
        flex-direction: column;
        padding-top: 10px;
    }

    .boxClosed {
        display: flex;
        flex-direction: row;
        box-shadow: 0px 4px 144px 0px rgba(0, 0, 0, 0.09);
        margin: 15px 0px;
        border-radius: 20px;
        padding: 18px 20px;
        align-items: center;
    }

    .boxOpen {
        align-items: center !important;
    }

    .boxP {
        display: inline !important;
    }

    .arrow {
        display: none;
    }

    .boxOpen {
        flex-direction: column;
        padding-top: 0px;
        margin: 20px;
    }

    .boxClosed {
        flex-direction: column;
        padding-top: 0px;
        margin: 20px;
    }

    .box-video {
        display: block;
        width: 100%;
        height: fit-content;

    }

    .accordion-video {
        display: none;
    }

    .campaignTitle {
        display: none;
    }

    .hero-section-spacer {
        height: calc(100vh - 150px);
    }

    .cardRow {
        flex-direction: column;
        gap: 20px;
        max-width: 520px;
    }

    .card {
        height: fit-content;
        width: 100%;
        margin: 0px;
    }


    .tabButtons {
        position: sticky;
        top: 89px;
        background-color: white;
        width: 100%;
        border-bottom: solid 1px rgb(0 0 0 / 5%);
        justify-content: center;
    }

    .tabsSection {
        padding: 0px;
        margin: 0px;

    }

    .line {
        display: none;
    }

    .darkText {
        font-size: 22px;
    }

    .blueText {
        font-size: 22px;
    }

    .hero-section-content {
        margin-top: -130px;
    }

    .halfed-hero {
        height: 500px !important;

    }
}

@media screen and (max-width: 800px) {
    .textColumn {
        width: 100%;
    }


    .cardRow {
        max-width: auto;
        width: 90%;
    }

    .support-nav {
        justify-content: center;
    }

    .hero-section>video {
        height: 100vh;
    }

    .hero-section-content {
        width: 100%;



    }

    .tabButton>h4 {
        font-size: 15px;
    }


    .hero-section-content>span {
        display: none;
    }

    .navbar {
        padding: 20px 20px
    }

    .hero-section-content>h1 {
        font-size: 40px;
        line-height: 43px;
    }

    .hero-section-buttons>a {
        font-size: 14px;
    }

    .support-nav-right {
        display: none;
    }

    .hero-section-content {
        width: 100%;
    }
}