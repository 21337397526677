/* top 2 rows */
.personalizedRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
    margin-top: 100px;

}

.personalizedRow:first-of-type {
    margin-top: 60px;
}

.sideText-page {
    width: 485px;
}

.sideText-page>h4 {
    color: #0053ED;
    font-size: 22px;
    font-weight: 700;
}

.sideText-page>p {
    font-weight: 400;
    color: black;
    font-size: 17px;
    line-height: 31px;
    margin-top: 5px;
}

.checkList {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 35px;
}

.checkItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.checkItem>h4 {
    font-weight: 700;
    font-size: 19px;
    line-height: 25px;
    color: #002467;
}

.checkItem>i {
    color: #0053ED;
    font-size: 36px;
}


.sideText-page>h2 {
    color: #002467;
    line-height: 46px;
    font-size: 39px;
    font-family: 'Josefin Sans', sans-serif !important;
    font-weight: 700;
    margin-top: 10px;

}

.smallTitle-pages {
    margin: auto;
    text-align: center;
    color: #0053ED;
    font-size: 22px;
    font-weight: 700;
    margin-top: 170px;
}

.bigTitle-pages {
    margin: auto;
    width: 470px;
    text-align: center;
    color: #002467;
    line-height: 46px;
    font-size: 39px;
    font-family: 'Josefin Sans', sans-serif !important;
    font-weight: 700;
    margin-top: 10px;
}

.personalizedRow>img {
    border-radius: 30px;
}

#firstLeftImage {
    width: 545px;
}

#secondRightImage {
    width: 560px;
}

/* CARDS SECTION */
.cardRowPages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 60%;
    max-width: 1200px;
    margin: auto;
    margin-top: 30px;


}

.cardpages {
    background-color: rgba(249, 249, 249, .16);
    ;
    margin: 20px;
    border-radius: 29px;
    box-sizing: border-box;
    overflow: hidden;
    width: 30%;
    height: auto;
    box-shadow: 0px 4px 144px 1px rgba(0, 0, 0, 0.06);
    min-height: 480px;

}

.cardpages>i {

    object-fit: contain;
    box-sizing: content-box;
    padding: 14px;
    color: white;
    background-color: #0053ED;
    border-radius: 50px;


}

#icon2 {
    object-fit: contain;
    box-sizing: content-box;
    padding: 14px;
    color: white;
    background-color: #0053ED;
    border-radius: 50px;
    width: 25px;
    height: 25px;
}

.learnMoreImgs {

    width: 100%;
}

.cardTitlepages {
    color: #002467;
    font-size: 22px;
    font-weight: 700;
    margin-top: 15px;
    padding: 0 25px;

}

.cardTextpages {
    color: #002467;
    line-height: 27px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 5px;
    padding: 0 25px;


}

/* LAUNCH BACKGROUND COMPONENET */

.launchBackground-page {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 190px 0 135px 0;
}

.launchBackground-page>img {
    width: 100%;
}

.backgroundOverlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 420px;
}

.backgroundOverlay>h2 {
    line-height: 56px;
    font-size: 49px;
    font-weight: 700;
    font-family: 'Josefin Sans', sans-serif !important;
    color: white;

}

.backgroundOverlay>p {
    color: white;
    line-height: 27px;
    font-size: 16px;
    font-weight: 400;
}

.backgroundOverlay>a {
    margin-top: 48px;
}

.backgroundButton-page {
    width: 270px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: white;
    gap: 25px;

}

.backgroundButton-page>p {
    font-weight: 700;
    font-size: 17px;
    line-height: 34px;
    color: #0053ED;
}

.backgroundButton-page>i {
    font-size: 33px;
    color: #0053ED;
}

/* backgroundOverlay */

.video-halfed {
    height: 80vh !important;
}

@media screen and (max-width: 1130px) {
    .personalizedRow {
        flex-direction: column-reverse;
        max-width: 555px;
        margin: auto;
        gap: 30px;

    }

    .card {
        white-space: pre-wrap;
    }

    .bigTitle-pages {
        width: 100%;
        margin-top: 0px;
    }

    .personalizedRow:first-of-type {
        margin-top: 0px;
        flex-direction: column;
    }




    #firstLeftImage {
        width: 100%;
    }

    .video-halfed {
        height: 500px !important;
    }

    .hero-section-content {}

    .personalizedRow-container {
        margin-top: 28px !important;
        display: flex;
        flex-direction: column;
        gap: 100px;
        margin: 25px;
    }

    .sideText-page>h2 {
        line-height: 36px;
        font-size: 30px;
    }

    #secondRightImage {
        width: 100%;
    }

    .sideText-page {
        width: 100%;
    }

    .cardRowPages {

        gap: 0px;
        overflow-x: scroll;
        justify-content: flex-start;
        white-space: nowrap;
        display: block;
    }

    .backgroundOverlay {
        width: 100%;
        max-width: 555px;
    }

    .backgroundOverlay>h2 {
        text-align: center;
    }

    .launchBackground-page {
        margin: 120px 0 135px 0
    }

    .launchBackground-page>img {
        width: 100%;
        height: 440px;
        object-fit: cover;
    }

    .cardpages {
        width: 300px;
        min-height: auto;
        height: 470px !important;
        display: inline-block !important;
        margin: 10px;
        height: fit-content;
        white-space: pre-wrap;
    }

    .cardTitlepages {
        font-size: 22px;
    }
}


@media screen and (max-width: 800px) {
    .sideText-page>h2 {
        line-height: 36px;
        font-size: 25px;
    }

    .sideText-page>h4 {
        font-size: 20px;
    }

    .bigTitle-pages {
        font-size: 25px;
        line-height: 36px;
        padding: 22px;
        text-align: left;
        padding-top: 0px;
    }

    .backgroundOverlay>h2 {
        line-height: 36px;
        font-size: 30px;

    }

    .rightPics {
        display: none;
    }

    .cardRowPages:last-of-type>div:first-of-type {
        margin-left: 22px;
    }

    .smallTitle-pages {
        text-align: left;
        padding-left: 22px;
    }

    .backgroundOverlay>a {
        margin-top: 20px;
    }
}